.title {
    text-align: center !important;
    align-items: center !important;
    margin: 1rem !important;
    padding: 1rem;
    border: none;
    border-radius: 10px;  
}

.search {
    background-color: #59d099;
}

.dropdown-toggle {
    background-color: #198754;
    border-color: #198754;
}

#light .title.list-group-item {
    background-color: #0d6efd;
    border-color: #0d6efd !important;
    color: white;
}

#dark .title {
    background-color: #212529;
    color: white;
}

#dark .cont{
    background-color: #212529;
    color: white;
}

#light .cont{
    background-color: white;
    color: #464f57;
}

.search{
    margin: 0px !important;   
}

.btn-flat {
    display: block !important;
    border-radius: 50% !important;
    padding: 0.9rem !important;
    border-color: white !important;
    background-color: rgb(209, 52, 106) !important;
}
.btn-flat:hover {
    background: rgb(209, 52, 106) !important;
}
.btn-flat:active {
    border-color: white !important;
    background-color: rgb(209, 52, 106) !important;
}
