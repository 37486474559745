 .box {
    justify-content: center;
    align-items: center;
    display: grid;
    place-items: center;
    width: 100vw;
    padding: 1rem;
}

.workspaceItem {
    background-color: #ffffff;
    display: grid;
    flex-direction: column;
    place-items: center;
    width: 90vw;
}

