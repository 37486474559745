.App {
  text-align: center;
  display: flex;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
}

#light .App{
  color: black;
  background-color: #f3f3f3;
}

#dark .App{
  color: white;
  background-color: #484f5d;
}

.auth-form-container,
.login-form,
.register-form {
  display: flex;
  flex-direction: column;
}

@media screen and (min-width: 600px) {
  .auth-form-container {
    padding: 5rem;
    border: 1px solid black;
    border-radius: 10px;
    margin: 0.5rem;
    display: flex;
  }
}

label {
  text-align: left;
  padding: 0.25rem auto;
  margin: 0.5rem;
}

input {
  margin: 0.5rem !important;
  padding: 1rem;
  border: 2px solid #484f5d;
  border-radius: 10px;
}

button {
  border: none;
  background-color: black;
  padding: 20px;
  border-radius: 10px;
  cursor: pointer;
  color: white;
}

.link-btn {
  background: none;
  color: white;
  text-decoration: underline;
}

.language-checkbox{
  text-align: right;
}

@font-face {
  font-family: "MontHeavy";
  src: url("../../fonts/montHeavy.otf") format("opentype")
}

#font-face-mb{
  font-family: "MontHeavy";
}

.kanbanStarLogo{
  max-width: 10%;
  height: auto;
}
