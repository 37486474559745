.switch {
    position: relative;
    display: inline-block;
    margin: 0 5px;
  }

  .switch>span {
    position: absolute;
    top: 14px;
    pointer-events: none;
    font-family: 'Helvetica', Arial, sans-serif;
    font-weight: bold;
    font-size: 12px;
    text-transform: uppercase;
    text-shadow: 0 1px 0 rgba(0, 0, 0, .06);
    width: 50%;
    text-align: center;
  }
  
  input.check-toggle-round-flat:checked~.off {
    color: #2f3132;
  }
  
  input.check-toggle-round-flat:checked~.on {
    color: #fff;
  }
  
  .switch>span.on {
    left: 0;
    padding-left: 15px;
    color: #2f3132;
  }
  
  .switch>span.off {
    right: 0;
    padding-right: 10px;
    color: #fff;
  }
  
  .check-toggle {
    position: absolute;
    margin-left: -9999px;
    visibility: hidden;
  }
  
  .check-toggle+label {
    display: block;
    position: relative;
    cursor: pointer;
    outline: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  input.check-toggle-round-flat+label {
    padding: 2px;
    width: 97px;
    height: 35px;
    background-color: #2f3132;
    -webkit-border-radius: 60px;
    -moz-border-radius: 60px;
    -ms-border-radius: 60px;
    -o-border-radius: 60px;
    border-radius: 60px;
  }
  
  input.check-toggle-round-flat+label:before,
  input.check-toggle-round-flat+label:after {
    display: block;
    position: absolute;
    content: "";
  }
  
  input.check-toggle-round-flat+label:before {
    top: 2px;
    left: 2px;
    bottom: 2px;
    right: 2px;
    background-color: #2f3132;
    -webkit-border-radius: 60px;
    -ms-border-radius: 60px;
    -o-border-radius: 60px;
    border-radius: 60px;
  }
  
  input.check-toggle-round-flat+label:after {
    top: 4px;
    left: 2.8px;
    bottom: 4px;
    width: 48px;
    background-color: #fff;
    -webkit-border-radius: 52px;
    -moz-border-radius: 52px;
    -ms-border-radius: 52px;
    -o-border-radius: 52px;
    border-radius: 52px;
    -webkit-transition: margin 0.2s;
    -moz-transition: margin 0.2s;
    -o-transition: margin 0.2s;
    transition: margin 0.2s;
  }
  
  /*input.check-toggle-round-flat:checked + label {
    }*/
  
  input.check-toggle-round-flat:checked+label:after {
    margin-left: 44px;
  }
  