#datepicker{
    margin: 0px;
    padding: 0px;
    color: rgb(49, 108, 244);
}

#datepicker::placeholder{
    color: rgb(49, 108, 244);
}

.btn-flat{
    background-color: rgb(56, 101, 182);
    color: rgb(40, 167, 69);
    border-radius: 50%;
    width: fit-content;
    border-color: white;
}

.cardInput{
    margin: 0px !important;
}

.prop1{
    margin: 0px !important;
    font-size: small;
}

.modalFooter{
    background-color: rgb(56, 101, 182) !important;
    align-items: center !important;
}

.cardInputBox{
    margin: 0px !important;
    padding: 3% !important;
    border-width: 1px !important;
    border-color: rgba(255, 255, 255, 0.15) !important;
    color: white !important;
}

.cardInputBox::placeholder{
    color: rgba(255, 255, 255, 0.8) !important;
}

.drop1{
    max-width:fit-content !important;
    margin: 0px !important;
    padding: 3% !important;
    background-color: rgb(209, 52, 106) !important;
    color: white !important;
    border-radius: .5rem;
}

.cardDescriptionBox{
    margin: 0px !important;
    padding: 3% !important;
    color: rgb(49, 108, 244) !important;
}

.cardDescriptionBox::placeholder{
    color: rgb(49, 108, 244) !important;
}