a {
    color: #2f3ecf;
}

.modalTitle{
    margin: 0;
    text-align: center;
}

.commentStyle{
    white-space: pre;
    text-rendering: auto;
}

.ownAD{
    justify-content: right;
}

.tp_AD{
    justify-content: left;
}

.ownAuthorDate{
    font-size: small;
    text-align: right;
}

.tp_authorDate{
    font-size: small;
    text-align: left;
}

.dateComment{
    color: #b0b1b4;
}

#light .ownComment{
    margin-left: 30%;
    background-color: rgba(172, 211, 223,0.6);
    padding: 2%;
    border-radius: 20px;
    max-width: 70%;
    word-wrap: break-word;
    text-align: left;
}

#light .tp_comment{
    background-color: rgba(206, 212, 218, 0.5) ;
    padding: 2%;
    border-radius: 20px;
    max-width: 70%;
    word-wrap: break-word;
    text-align: left;
}

#dark .ownComment{
    color: black;
    margin-left: 30%;
    background-color: rgba(205, 221, 226, 0.9);
    padding: 2%;
    border-radius: 20px;
    max-width: 70%;
    word-wrap: break-word;
    text-align: left;
}

#dark .tp_comment{
    background-color: rgba(206, 212, 218, 0.5) ;
    padding: 2%;
    border-radius: 20px;
    max-width: 70%;
    word-wrap: break-word;
    text-align: left;
}

.footer{
    display: block;
}

#light .commentForm{
    color: black;
    background-color: white;
}

#dark .commentForm{
    color: white;
    background-color: #484f5d;
}

.formControlAndButton{
    text-align: right;
}

#deleteFiles{
    margin: 0;
    border-top-left-radius: 0; 
    border-bottom-left-radius: 0;
}

#filesInputLabel{
    background-color: #0d6efd; 
    color: white;
    border-top-left-radius: 0.375rem; 
    border-bottom-left-radius: 0.375rem;
    display: inline-block;
    padding: 5px 12px 9px;
    margin-top: 0;
    margin-right: 0px;
    cursor: pointer;
}

input[type="file"]{
    display: none;
}

.footerButtons{
    display: flex;
    justify-content: space-evenly;
}