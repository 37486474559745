.App2 {
    min-height: 100vh;
    padding-top: 11vh;  
}

#light{
    background-color: #f3f3f3;
}

#dark{
    color: white;
    background-color:#484f5d;
}

